<template>
  <h1>This is a communication preferences componenets</h1>
</template>

<script>
import {onMounted} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
    name:"CommunicationPreference",
      setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("CommunicationPreference", ["Account"]);
    });

}
}
</script>

<style>

</style>